<template>
  <div class="gameroom">
    <div class="bordered lightblue" style="width: 100%; max-height: 100%; overflow: auto;">
      <span class="formheader">{{BoardStore.playlist.gamename}}</span>
      <br>
      <span v-if="BoardStore.playlist.ownerid">
        {{t('CARTOON.AUTHOR')}} {{BoardStore.playlist.ownerlogin}}
        <span v-if="BoardStore.playlist.www">
          (
         <span v-if="www" class="asreference" @click="Mydatastore.openreference(BoardStore.playlist.www)">
           {{BoardStore.playlist.www}}
         </span>
         <span v-if="! www" >
           {{BoardStore.playlist.www}}
         </span>
         )
       </span> 
      </span>
      <span>{{BoardStore.playlist.descr}}</span>
      <div class="bordered maxheight200 autoscroll">
        <PlayListTree ref="playlisttree"/>
      </div>
      <div class="bordered" style="width: 96%;">
        <span v-if="BoardStore.cartoonmode == 'CARTOON'">{{t('CARTOON.CARTOON')}}:<img src="tv.png" width="24" height="24"></span>
        <span v-if="BoardStore.cartoonmode != 'CARTOON'">{{t('CARTOON.TRAINING')}}:<img src="training.png" width="24" height="24"></span>
        <div>
          <table width="100%">
            <tr>
              <td width="30%" align="left">
                <a-checkbox v-model:checked="iamwhite" @change="recalcmode">
                  {{t('CARTOON.IAMWHITE')}}
                </a-checkbox>
              </td>
              <td width="30%" align="left">
                <a-checkbox v-model:checked="iamblack" @change="recalcmode">
                  {{t('CARTOON.IAMBLACK')}}
                </a-checkbox>
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2" align="left">
                <a-checkbox v-model:checked="shcomm" @change="setshcomm">
                  {{t('CARTOON.SHOWCOMMENTS')}}
                </a-checkbox>
                <span v-if="shcomm">
                  <select v-model="seconds" @change="setseconds">
                    <option value="1">1</option>  
                    <option value="2">2</option>  
                    <option value="3">3</option>  
                    <option value="4">4</option>  
                    <option value="5">5</option>  
                  </select>
                  {{t('CARTOON.SECONDS')}}
                </span>
              </td>
              <td>
                <a-checkbox v-if="BoardStore.cartoonmode == 'CARTOON'" v-model:checked="stoponfork" @change="setstoponfork">
                  {{t('CARTOON.STOPONFORK')}}
                </a-checkbox>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="bordered" style="width: 96%;">
        <span>{{t('CARTOON.CONTROL')}}:</span>
        <div class="width96left">
          <table align="left" width="100%">
            <tr>
              <td>
                <a-popover>
                  <template #content>
                    {{t('CARTOON.TOSTARTHINT')}}
                  </template>
                  <button :disabled="(BoardStore.offsetinsideslave == 0)&&(BoardStore.currentslaveindex==-1)" @click="BoardStore.selectslave(-1)">{{t('CARTOON.TOSTART')}}</button>
                </a-popover>
              </td>
              <td>
                <a-popover>
                  <template #content>
                    {{t('CARTOON.BACKCHAIN')}}
                  </template>
                  <button :disabled="BoardStore.offsetinsideslave == 0" @click="backchain">&lt;&lt;</button>
                </a-popover>
                <a-popover>
                  <template #content>
                    {{t('CARTOON.STEPBACK')}}
                  </template>
                  <button :disabled="BoardStore.snapchain.length==0" @click="BoardStore.stepback">&lt;</button>
                </a-popover>
                <a-popover>
                  <template #content>
                    {{t('CARTOON.PAUSE')}}
                  </template>
                  <button :disabled="! BoardStore.run" @click="pause">||</button>
                </a-popover>
                <a-popover>
                  <template #content>
                    {{t('CARTOON.STEPFORWARD')}}
                  </template>
                  <button :disabled="BoardStore.nowismychoice()" @click="step">></button>
                </a-popover>
                <a-popover>
                  <template #content>
                    {{t('CARTOON.PLAY')}}
                  </template>
                  <button :disabled="BoardStore.trainingmode=='3'" @click="play"><span :class="getcolor()">>></span></button>
                </a-popover>
              </td>
              <td>
                <a-popover v-if="BoardStore.cartoonmode != 'CARTOON'">
                  <template #content>
                    {{t('CARTOON.RANDOMHINT')}}
                  </template>
                  <button @click="setrandom">{{t('CARTOON.RANDOMBUTT')}}</button>
                </a-popover>
              </td>
              <td>
                <a-popover>
                  <template #content>
                    {{t('CARTOON.BOOKMARKHINT')}}
                  </template>
                  <button @click="addbm">{{t('CARTOON.BOOKMARK')}}</button>
                </a-popover>
                <select v-if="BoardStore.bookmarks.length" v-model="bookmark2go">
                  <option v-for="bm in BoardStore.bookmarks" :key="bm.id" :value="bm.id">{{bm.txt}}</option>
                </select>
                <a-popover>
                  <template #content>
                    {{t('CARTOON.GOTOHINT')}}
                  </template>
                  <button v-if="BoardStore.bookmarks.length" @click="BoardStore.gotobm(bookmark2go)">{{t('CARTOON.GOTO')}}</button>
                </a-popover>
              </td>
              <td>
                <a-popover>
                  <template #content>
                    {{t('CARTOON.HINTHINT')}}
                  </template>
                  <button :disabled="BoardStore.hintbuttondisabled()" @mousedown="BoardStore.showhint()" @mouseup="BoardStore.hidehint()" @mouseout="BoardStore.hidehint()">
                    {{t('CARTOON.HINT')}}
                  </button>
                </a-popover>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="bordered" style="width: 96%;">
        <span>{{t('CARTOON.OTHERS')}}:</span>
        <div class="width96left">
          <table align="left" width="100%">
            <tr>
              <td>
                <a-popover>
                  <template #content>
                    {{t('CARTOON.YOUMOVEHINT')}}
                  </template>
                  <button @click="newchain()">{{t('CARTOON.YOUMOVE')}}</button>
                </a-popover>
              </td>
              <td>
                <a-popover>
                  <template #content>
                    {{t('CARTOON.INEDITORHINT')}}
                  </template>
                  <button v-if="BoardStore.playlist.ownerid == Mydatastore.mydata.id" @click="editchain()">{{t('CARTOON.INEDITOR')}}</button>
                </a-popover>
              </td>
              <td>
                <a-popover>
                  <template #content>
                    {{t('CARTOON.ADDCHAINHINT')}}
                  </template>
                  <button v-if="(BoardStore.playlist.ownerid == Mydatastore.mydata.id)&&(BoardStore.playlist.source!='recent')" @click="addchain()">{{t('CARTOON.ADDCHAIN')}}</button>
                </a-popover>
              </td>
              <td>
                <a-popover>
                  <template #content>
                    {{t('CARTOON.DISCUSSIONHINT')}}
                  </template>
                  <button v-if="BoardStore.playlist.commenabled && (BoardStore.playlist.source!='recent')" @click="discuss">{{t('CARTOON.DISCUSSION')}}</button>
                </a-popover>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import {useMyDataStore} from '@/store/MyData.js'
  import { defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n'
  import {useBoardStore} from '@/store/BoardStore.js'
  import PlayListTree from '@/components/PlaylistTree.vue'
  import * as SOCKETUTILS from '@/hooks/socketutils.js'
  
  export default defineComponent({
    components:{
      PlayListTree
    },
    data(){
      this.BoardStore.cartoonmode='CARTOON'
      return{
        www:this.Mydatastore.likereference(this.BoardStore.playlist.www),
        iamwhite:false,
        iamblack:false,
        bookmark2go:null,
        stoponfork:this.Mydatastore && this.Mydatastore.mydata && this.Mydatastore.mydata.profprivate && 
                   (this.Mydatastore.mydata.profprivate.stoponfork == '1'),
        shcomm:this.Mydatastore && this.Mydatastore.mydata && this.Mydatastore.mydata.profprivate && 
                   (this.Mydatastore.mydata.profprivate.showcomm == '1'),
        seconds:(this.Mydatastore && this.Mydatastore.mydata && this.Mydatastore.mydata.profprivate && 
                   (this.Mydatastore.mydata.profprivate.showcommpause >=0))?this.Mydatastore.mydata.profprivate.showcommpause:3
      }
    },
    setup() {
  
      const { t } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      });
  
      const Mydatastore = useMyDataStore()
      const BoardStore=useBoardStore()

      BoardStore.trainingmode='IAMWATCHER'
      return {
        t,
        BoardStore,
        Mydatastore
      }
    },
    methods:{
      addbm(){
        let text=prompt(this.t('CARTOON.BMNAME'))
        if (text){
          this.BoardStore.addbm(text)
          this.bookmark2go=this.BoardStore.bookmarks.length-1
        }
      },
      backchain(){
        if (this.BoardStore.currentslaveindex>=-1){
          this.BoardStore.selectslave(this.BoardStore.currentslaveindex)
          this.recalcmode()
        }
      },
      editchain(){
        this.BoardStore.selectslave(this.BoardStore.currentslaveindex)
        this.BoardStore.createeditchain(this.BoardStore.currentslaveindex)
        this.BoardStore.editchainmode='editchain'
        this.Mydatastore.showProtocol()
      },
      addchain(){
        this.BoardStore.createeditchain(null)
        this.BoardStore.editchainmode='addchain'
        this.Mydatastore.showProtocol()
      },
      newchain(){
        this.BoardStore.createeditchain(null)
        this.BoardStore.editchainmode='newchain'
        this.Mydatastore.showProtocol()
      },
      getcolor(){
        return this.BoardStore.run?"redplay":"blackplay"
      },
      pause(){
        this.BoardStore.run=false
      },
      play(){
        this.BoardStore.run=true
        if (!this.BoardStore.nowismychoice()){
          this.BoardStore.play()
        }
      },
      step(){
        if (!this.BoardStore.nowismychoice()){
          this.BoardStore.play()
        }
      },
      recalcmode(){
        this.BoardStore.cartoonmode=(this.iamwhite || this.iamblack)?'TRAINING':'CARTOON'
        if (this.iamwhite){
          this.BoardStore.trainingmode=this.iamblack?'IAMBOTH':'IAMWHITE'
        } else {
          this.BoardStore.trainingmode=this.iamblack?'IAMBLACK':'IAMWATCHER'
        }
        //if (this.BoardStore.trainingmode == '3'){
          this.BoardStore.run=false
        //}
      },
      setrandom(){
        let turn
        if (this.iamwhite && this.iamblack){
          turn=Math.random()>0.5?'w':'b'
        } else {
          turn=this.iamwhite?'w':'b'
        }
        this.BoardStore.startrandomly(turn)
      },
      setstoponfork(){
        this.Mydatastore.mydata.profprivate.stoponfork=this.stoponfork?'1':'0'
        SOCKETUTILS.saveprivateprofile('stoponfork',this.Mydatastore.mydata.profprivate.stoponfork)
      },
      setshcomm(){
        this.Mydatastore.mydata.profprivate.showcomm=this.shcomm?'1':'0'
        SOCKETUTILS.saveprivateprofile('showcomm',this.Mydatastore.mydata.profprivate.showcomm)
      },
      setseconds(){
        this.Mydatastore.mydata.profprivate.showcommpause=this.seconds
        SOCKETUTILS.saveprivateprofile('showcommpause',this.seconds)
      },
      wwwclass(){
        if (this.BoardStore.playlist.www.match(new RegExp("http")) || this.BoardStore.playlist.www.match(new RegExp("www"))){
          return "asreference"
        } else {
          return null
        }
      },
      openwww(){
        window.open(this.BoardStore.playlist.www,'_blank');
      },
      wwwclick(){
        if (this.BoardStore.playlist.www.match(new RegExp("http")) || this.BoardStore.playlist.www.match(new RegExp("www"))){
          return "openwww"
        } else {
          return null
        }
      },
      discuss(){
        this.BoardStore.run=false
        this.Mydatastore.where2switch='cartoon'
        this.Mydatastore.showDiscussion()
      }
    },
  })
</script>

<style type="text/css">
  .redplay{
    color: red;
  }
  .blackplay{
    color: black;
  }
</style>